.call {
    flex:1;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
}

.call-tools {
    display: flex;
    justify-content: space-between;
}

.call-toolsLeft {
    display: flex;
}

.call-tools-right {
    display: flex;
}

.call-button-right {
    margin-bottom: 5px !important;
    vertical-align: bottom !important;
}

.call-body {
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 22px;
    border-radius: 10px;
}

.call-bodyHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    padding: 5px;
    position: relative;
    justify-content: space-between;
}

.call-bodyHeader > h2 {
    font-weight: 400;
    margin-right: 20px;
}

.call-bodyHeader > p {
    margin-left: 20px;
}

.call-time {
    font-size: 10px;
    color: grey;
    line-height: 20px;
}
.call-msg-time {
    font-size: 10px;
    color: grey;
}

.call-message > p {
    padding: 10px;
    margin-right: 20px;
    overflow-wrap: break-word;
}
.call-subject{
    display: flex;
}
.call-subject-content{
    display: flex;
    flex-direction: column;
    position: relative;
}

.call-content {
    overflow: scroll;
}

.call-msg-text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.call-msg{
    margin: 10px;
}

.call-msg-title {
    font-weight: bold;
    color: #2585c0;
}

.incoming-call {
    animation: slide_down 0.5s ease-in-out
}
.incoming-call-card {
    font-size: 8pt;
    margin: 10px;
    box-shadow: 0 0 4px 1px #909090;
    border-radius: 5px;
}

.incoming-call-top {
    display: flex;
    cursor: pointer;
}

.incoming-call-top-image {
    margin-right: 10px;
}

.incoming-call-button-hangup {
    margin-right: 10px;
}
.incoming-call-button-hangup svg {
    color: #b02424;
}
.incoming-call-button-answer svg {
    color: #20bb20;
}

.incoming-call-top svg {
    width: 30px;
    height: 30px;
}

.incoming-call-details {
}

.incoming-call-buttons {
    font-size: 10pt;
    text-align: right;
    margin-right: 10px;
    display: flex;
}
.incoming-call-buttons span{
    font-size: 10pt;
}
.incoming-call-buttons svg {
    width: 20px;
    height: 20px;
}

.incoming-call-title {
    padding-top: 5px;
    font-size: 10pt;
    font-weight: bold;
}

.menu-logo {
    width: 30px;
    height: 30px;
}

.incoming-call-menu {
    margin-top: -10px;
}

.incoming-call-menu .MuiSpeedDial-fab {
    background: transparent !important;
    box-shadow: none !important
}

.full-incoming-call {
    flex:1;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
}
.screening-text {
    width: 100%;
}
.screening-send {
}
.screening-text-container {
    position: absolute !important;
    bottom: 10px;
    width: 71%;
    display: flex;
    justify-content: space-between;
}

.full-incoming-call-buttons {
    width: 250px;
    margin: auto;
}

.full-incoming-call-bottom-buttons {
    display: flex;
    justify-content: space-between;
}

#audio_output {
    vertical-align: middle;
}


@media only screen and (max-width: 700px) {   
    .call-bodyHeader{
    display: grid !important;
    margin-left: 0;
    }

    .call-time {
        position: unset;
        margin-top: 5px;
    }
    
    .call-bodyHeader > p{
        margin-left: 0;
    }
    .call-message > p {
        margin-left: 10px;
    }
}