.app{
    height: 100vh;
}

.app, .login {
    background: #f6f6f6;
    padding: 10px;
}

.app-content {
    flex: 1;
    overflow: scroll;
    position: relative;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
}

.app-body{
    display: flex;
    height: 100vh;
}

.app-title {
    margin: 10px;
    color: #3d78d1;
}

.menu_bottom {
    font-size: 9pt;
    text-align: center;
}

.logo_bottom_menu {
    width: 10px;
    vertical-align:middle;
}

.logo_callassistant {
    font-family: Spartan, serif !important;
    color: #2a64a1 !important;
    font-weight: 700;
    text-transform: lowercase !important;
}
.logo_callassistant_bottom {
    font-family: Spartan, serif !important;
    color: #1a52c9 !important;
    font-weight: 700;
    text-transform: lowercase !important;
}
.logo_dotai {
    font-family: Spartan, serif !important;
    color: #000000 !important;
    font-weight: 700;
    text-transform: lowercase !important;
}

.itemRow:hover {
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 4px 4px -2px rgba(0,0,0,0.24);
}

.itemRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
    z-index: 999;
    padding: 5px;
}

.page-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    z-index: 999;
    padding-right: 10px;
}

.page-settingsLeft {

}
.page-settingsRight {
    display: flex;
    align-items: center;
}

.pincode-input-text {
    border-radius: 5px !important;
    border-width: 2px !important;
}

.loading {
    fill: #3d78d1 !important;
    margin: 100px auto;
}

.nowrap {
    white-space: nowrap ;
}

@media print {
    .sidebar {
        display: none;
    }
    .header, .call-tools-right, .call-tools, .voicemail-tools-right, .voicemail-tools{
        display: none !important;
    }
    .call, .voicemail {
        top: 0 !important;
    }
}


.fadeIn {
    animation: fadeInAnimation 700ms linear;
}

@-webkit-keyframes fadeInAnimation {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.bg {
    background-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
    .logo_callassistant_bottom {
        color: #1a52c9 !important;
    }

    .logo_dotai {
        color: #ffffff !important;
    }

    .bg {
        background-color: #444444 !important;
    }

    body {
        background-color: #444444 !important;
        color: #ffffff !important;
        background-image: url(https://i.pinimg.com/originals/d8/1f/5e/d81f5e5fb8966a22087bd0209d5870d3.jpg) !important;
        background-size: cover;
    }

    .itemRow {
        border-bottom: 1px solid #404040 !important;
    }

    .settings-sections {
        border-bottom: 1px solid #303030 !important;
    }

    .MuiInputBase-root, .MuiFormLabel-root, .MuiPaper-root {
        color: white !important;
        background-color: #444444 !important;
    }

    .MuiButton-label {
        color: white !important;
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid rgb(228 228 228 / 42%);
    }

    .MuiDialogContentText-root {
        color: white !important;
    }

    .page-settings {
        border-bottom: 1px solid #404040;
    }

    .page-settingsLeft,
    .page-settingsRight,
    .deviceRow-message svg,
    .header-right svg,
    .call-tools,
    .button-icon img,
    .voicemail-tools,
    .call-bodyHeader svg,
    .account svg,
    .voicemail-bodyHeader svg {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }

    .callRow {
        border-bottom: 1px solid #404040 !important;
    }

    .voicemailRow-unread svg {
        filter: none !important;
    }

    .itemRow-options svg {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }

    .itemRow:hover {
        border-top: 1px solid #404040;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.24);
    }

    .sidebarOption--active {
        background-color: #303030 !important;
    }

    .header {
        border-bottom: 1px solid #505050 !important;
    }

    .header-middle > input {
        color: white !important;
    }

    mark {
        background-color: #666;
        color: white;
    }

    .call-bodyHeader {
        border-bottom: 1px solid #303030 !important;
    }

    .voicemail-bodyHeader {
        border-bottom: 1px solid #303030 !important;
    }
    .search-results {
        background-color: #303030 !important;
    }
    .sidebarOption:hover,
    .sidebarOption--active
     {
        background-color: #6a6c70d4 !important;
        color: #3780c0 !important;
        font-weight: 800 !important;
    }

    .header-logo-top {
        content: url(https://admin.callassistant.app/images/ca_logo_text_white.png);
    }
    .login-logo {
        content: url(https://admin.callassistant.app/images/ca_logo_text_white.png);
    }

    .section--selected {
        background: #3e3e3e;
    }

    .menu_bottom a {
        color: white !important;
    }

    .sidebarOption {
        color: #f0f0f0 !important;
    }

    .app, .login {
        background: #222;
    }
    .app-content {
        background: #111;
    }


    .sidebar-user-content-bg {
        background: linear-gradient(180deg, rgba(2,0,36,0) -3%, #0d0d0d 94%) !important;
    }

}