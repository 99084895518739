.callRow {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
    z-index: 999;
}

.callRow:hover {
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 4px 4px -2px rgba(0,0,0,0.24);
}

.callRow-options {
    display: flex;
}

.callRow-message {
    display: flex; 
    flex:0.8;
    align-items: center;
    font-size: 13px;
}

.callRow-message > h4 {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

.callRow-title{
    font-size: 13px;
    flex: 0.3;
}

.callRow-time {
    padding-right: 15px;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    color: #888;
}

.callRow-spam {
    padding: 5px;
    background-color: #c91414;
    border-radius: 3px;
    color: white;
}

.callRow-description {
    font-weight: 400;
    color:grey;
}

@media only screen and (max-width: 700px) {   
    .callRow-options > .MuiIconButton-root{
        padding-left: 2px;
        padding-right: 2px;
    }

    .callRow-time {
        display: none;
    }
}