.account {
    flex: 1;
    overflow: scroll;
}

.callList-no-calls {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}


.callList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    z-index: 999;
    padding-right: 10px;
}

.callList-sections {
    position: sticky;
    top: 0;
    display: flex;
    border-bottom: 1px solid whitesmoke;
    z-index: 999;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.callList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.callList {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

.account-item-title {
    font-weight: bold;
    text-transform:uppercase;
    color: #3d78d1;
}
.account-item {
    color: #888;
}

.account-items {
    display: flex;
    padding: 10px;
}
.account-details {
    flex: 0.4;
}
.account-tips {
    max-width:700px;
    padding: 10px;
}

.account-image {
    border-radius: 10px;
}

.account-number-item {
    height: 55px;
}

.verify-code-block {
    margin: 20px;
}
.verify-code {
    font-weight: bold;
}

@media only screen and (max-width: 500px) {   
    
    .callList-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .callList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .callList-settingsLeft{
        margin-left: 10px;
    }

    .callList-settingsRight{
        margin-right: 10px;
    }
}