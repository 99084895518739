.sidebar {
    flex: 0.3;
    max-width: 300px;
    padding-right: 20px;
}

.sidebar-compose {
    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
    color: grey;
    padding: 15px 20px !important;
    border-radius: 30px !important;
    box-shadow: 0px 2px 5px -2px rgb(0, 0, 0, 0.75);
}

.sidebar-footer {
    display: flex;
    justify-content: center;
}

.sidebar-link{
    text-decoration: none;
}

.sidebar-user {
    height: 200px;
    background-size: cover;
    position: relative;
    border-radius: 10px;
}

.sidebar-user-name {
    font-weight: bold;
    font-size: 12pt;
}

.sidebar-user-content {
    font-size: 9pt;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
}

.sidebar-logo {
    text-align: center;
}

.sidebar-user-content-bg {
    background: linear-gradient(180deg, rgba(2,0,36,0) -3%, rgba(255,255,255,1) 94%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


@media only screen and (max-width: 500px) {
    .sidebar-footerIcons > .MuiIconButton-root{
       padding: 1px !important;
    }

    .sidebar-compose{
        margin-left: 3px !important;
        padding: 10px 8px !important;
    }
}