.login {
    display: grid;
    place-items: center;
    height: 100vh;
}

.login-container{
    display: flex;
    flex-direction: column;
}

.login-container > img {
    object-fit: contain;
    height: 200px;
    background: transparent;
}

.watch-video {
    background: white !important;
    color: #2a64a1 !important;
}

.video-player-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.video-close {
    position: fixed !important;
    top: 20px;
    right: 20px;
    color: white !important;
}
.video-player {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
}
