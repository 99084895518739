.contactList {
    flex: 1;
    overflow: scroll;
}

.contactList-no-contacts {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    position: absolute;
}


.contactList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    background-color: white;
    z-index: 999;
    padding-right: 10px;
}

.contactList-sections {
    position: sticky;
    top: 0;
    display: flex;
    border-bottom: 1px solid whitesmoke;
    background-color: white;
    z-index: 999;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.contactList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.contactList {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

.contact-selector-close {
    float: right;
}
.select-number-item {
    padding: 5px;
}
.contact-selector {
    position: absolute;
    width: 600px;
    height: 400px;
    z-index: 999;
    overflow: scroll;
    box-shadow: #ccc 1px 3px 7px 2px;
    border-radius: 10px;
    left: 113px;
    top: 101px;
    animation: slide_down 0.3s ease-in-out;
}

@media only screen and (max-width: 500px) {   
    
    .contactList-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .contactList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .contactList-settingsLeft{
        margin-left: 10px;
    }

    .contactList-settingsRight{
        margin-right: 10px;
    }
}