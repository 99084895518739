.sidebarOption {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
    color: #818181;
}

.sidebar-title {
    display: flex;
}

.sidebarOption:hover,
.sidebarOption--active
 {
    background-color: #e8f0f7db;
    color: #3780c0;
    font-weight: 800 !important;
}

.sidebarOption > h3 {
    flex: 1;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
}

.sidebarOption > p {
    display: none;
    font-weight: 300;
}

.sidebarOption--active > p {
    display: inline !important;
}

.sidebarOption:hover > p {
    display: inline;
}

.sidebarOption > .MuiSvgIcon-root{
    padding: 5px;
}

@media only screen and (max-width: 500px) {   
    .sidebarOption > .MuiSvgIcon-root{
        display: none;
    }
    
}