.numberList {
    flex: 1;
    overflow: scroll;
}

.add-number {
    position: absolute !important;
    bottom: 50px;
    right: 50px;

    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
    color: grey;
    padding: 15px 20px !important;
    border-radius: 30px !important;
    box-shadow: 0px 2px 5px -2px rgb(0, 0, 0, 0.75);
}

.numberList-no-numbers {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}


.numberList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    background-color: white;
    z-index: 999;
    padding-right: 10px;
}

.numberList-sections {
    position: sticky;
    top: 0;
    display: flex;
    border-bottom: 1px solid whitesmoke;
    background-color: white;
    z-index: 999;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.numberList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.numberList {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

@media only screen and (max-width: 500px) {   
    
    .numberList-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .numberList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .numberList-settingsLeft{
        margin-left: 10px;
    }

    .numberList-settingsRight{
        margin-right: 10px;
    }
}