.voicemailList {
    flex: 1;
    overflow: scroll;
    margin-bottom: 10px;
}

.voicemailList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    z-index: 999;
    padding-right: 10px;
}

.voicemailList-sections {
    position: sticky;
    top: 0;
    display: flex;
    background-color: white;
    z-index: 999;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.voicemailList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.voicemailList {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

@media only screen and (max-width: 500px) {   
    
    .voicemailList-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .voicemailList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .voicemailList-settingsLeft{
        margin-left: 10px;
    }

    .voicemailList-settingsRight{
        margin-right: 10px;
    }
}