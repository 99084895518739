.greetingList {
    overflow: scroll;
}
.greetingList-list {
    flex: 1;
}

.greetingList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    padding-right: 10px;
}

.greetingList-greeting {
    flex: 0.5;
}

.greetingList-sections {
    position: sticky;
    top: 0;
    display: flex;
    z-index: 999;
}

.greetings {
    display: flex;
    justify-content: space-between;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.greetingList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.greetingist {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

@media only screen and (max-width: 500px) {   
    
    .greetingist-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .greetingList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .greetingList-settingsLeft{
        margin-left: 10px;
    }

    .greetingList-settingsRight{
        margin-right: 10px;
    }
}