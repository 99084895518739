.contactRow {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
    z-index: 999;
}

.contactRow:hover {
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 4px 4px -2px rgba(0,0,0,0.24);
}

.contactRow-options {
    display: flex;
}

.contactRow-message {
    display: flex; 
    flex:0.8;
    align-items: center;
    font-size: 13px;
}

.contactRow-message > h4 {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

.contactRow-title{
    font-size: 13px;
    flex: 1;
}

.contactRow-time {
    padding-right: 15px;
    font-size: 9px;
    font-weight: bold;
}

.contactRow-description {
    font-weight: 400;
    color:grey;
}

@media only screen and (max-width: 700px) {   
    .contactRow-options > .MuiIconButton-root{
        padding-left: 2px;
        padding-right: 2px;
    }

    .contactRow-time {
        display: none;
    }
}