.greetingRow {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;
    z-index: 999;
}

.greetingRow:hover {
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 4px 4px -2px rgba(0,0,0,0.24);
}

.greetingRow-options {
    display: flex;
}

.greetingRow-message {
    display: flex; 
    flex:0.8;
    align-items: center;
    font-size: 13px;
}

.greetingRow-message > h4 {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

.greetingRow-title{
    font-size: 13px;
    flex: 1;
    overflow: initial;
    line-height: 20px;
}

.greetingRow-btn {
    font-size: 7pt !important;
    display: initial !important;
}

.greetingRow-time {
    padding-right: 15px;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    color: #888;
}

.greetingRow-description {
    font-weight: 400;
    color:grey;
}

.greetingRow-item {
    background-color: #277bc0;
    color: white;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    overflow: auto;
    border-radius: 3px;
}

@media only screen and (max-width: 700px) {   
    .greetingRow-options > .MuiIconButton-root{
        padding-left: 2px;
        padding-right: 2px;
    }

    .greetingRow-time {
        display: none;
    }
}