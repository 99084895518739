.callList {
    flex: 1;
    overflow: scroll;
}

.callList-no-calls {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}


.callList-settings {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    padding-right: 10px;
}

.callList-sections {
    position: sticky;
    top: 0;
    display: flex;
    z-index: 999;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.callList::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.callList {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}

.callList-list {
}

@media only screen and (max-width: 500px) {   
    
    .callList-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .callList-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .callList-settingsLeft{
        margin-left: 10px;
    }

    .callList-settingsRight{
        margin-right: 10px;
    }
}