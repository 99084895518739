.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
}

.header-user-img {
    cursor: pointer;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-left > img {
    object-fit: contain;
    height: 35px;
    margin-left: 5px;
}

.header-middle {
    display: flex;
    align-items: center;
    flex: 1;
    background-color: rgba(107, 103, 103, 0.16);
    padding: 2px 5px;
    margin: 10px;
    border-radius: 10px;
}

.header-logo-top {
    max-width: 180px;
    width: 80%;
    margin-top: 10px;
}

.header-middle > .MuiSvgIcon-root {
    color: grey;
}

.header-terms {
    font-size: 7pt;
}

.search-results {
    position: absolute;
    top: 73px;
    z-index: 9999;
    overflow: scroll !important;
    max-height: 300px;
    border-radius: 8px !important;
    font-size: 10pt;
    padding: 5px;
    width: 45%;
    animation: slide_down .3s ease-in-out;
    transform-origin: left top;
}

@keyframes slide_down{
    from{
        transform: scaleY(0);
    }
    to{
        transform: scaleY(1);
    }
}


.search-result-item {
    cursor: pointer;
}

.search-title {
    padding: 10px;
    font-size: 10pt;
    font-weight: bold;
}

.search-result-item-date {
    font-size: 7pt;
    font-style: italic;
    margin-top: 3px;
}

.header-middle > input {
    border: none;
    width: 100%;
    padding: 10px;
    outline: none;
    font-size: medium;
    background-color: transparent;
}

.header-right{
    display: flex;
    padding-right: 20px;
    align-items: center;
}


@media only screen and (max-width: 500px) {
    .header-left > .MuiIconButton-root{
        display: none !important;
    }
  
    .header-middle {
        margin: 10px 0px;
    }  
    .header-right > .MuiIconButton-root{
        padding: 5px !important;
        display: none !important;
    }
  }