.settings {
    flex: 1;
    overflow: scroll;
}

.settings-sections {
    position: sticky;
    top: 0;
    display: flex;
    border-bottom: 1px solid whitesmoke;
    z-index: 999;
}

.greetingText {
    width: 400px;
}

.greeting-text-container {
    display: table;
}

.greeting-container {
    display: flex;
}

.greeting-content {
}

.greeting-numbers {
    margin-left: 50px;
}

.greeting-number {
    margin-top: 10px;
    display: flex;
    width: 400px;
    justify-content: space-between;
}
.greeting-number-title {
    font-weight: bold;
}
.greeting-number-number {
    font-size: 9pt;
}

.generate-greeting-voicemail {
    margin: 5px;
    float: right;
}

.generate-greeting-assistant {
    margin: 5px;
    float: right;
}

.settings-gender svg {
    font-size: 30pt;
}

.call-buttons-content {
    width: 300px;
}

.menu-button-icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.buttons-editor {
    margin-bottom: 200px;
}
.button-color {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 20px;
    cursor: pointer;
}

.button-icon {
    cursor: pointer;
    margin: 20px;
    margin-top: -60px !important;
}

.button-icon-search {
    vertical-align: middle;
    float: right;
}
.alert-dialog-icons-content {
    width: 500px;
}
.alert-dialog-icons-title {
    width: 500px;
}
.button-icon-list {
    cursor: pointer;
    width: 30px;
    margin: 10px;
}

.button-text {
    width: 430px;
}

.button-title {
    width: 150px;
}

.buttons-menu-img {
    width: 50px;
    cursor: pointer;
}
.setting-buttons-menu{
    padding: 100px;
}

.settings-name {
    width: 300px;
}
.settings-container {
    width: 400px;
}
.settings-gender {
    text-align: left;
}
.settings-gender-icon {
    text-align: center;
    width: 50px;
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.settings::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.settings {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE and Edge */
   
}


@media only screen and (max-width: 500px) {   
    
    .settings-settingsLeft > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .settings-settingsRight > .MuiIconButton-root{
        padding-left: 3px;
        padding-right: 3px;
    }

    .settings-settingsLeft{
        margin-left: 10px;
    }

    .settings-settingsRight{
        margin-right: 10px;
    }
}