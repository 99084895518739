.voicemail {
    flex:1;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 80px;
}

.voicemail-tools {
    display: flex;
}

.voicemail-toolsLeft {
    display: flex;
}

.voicemail-tools-right {
    display: flex;
}

.voicemail-button-right {
    margin-bottom: 5px !important;
    vertical-align: bottom !important;
}

.voicemail-body {
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding: 20px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 25%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 20px;
    border-radius: 10px;
}

.voicemail-bodyHeader {
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
    justify-content: space-between;
}

.voicemail-bodyHeader > h2 {
    font-weight: 400;
    margin-right: 20px;
}

.voicemail-bodyHeader > p {
    margin-left: 20px;
}

.voicemail-time {
    font-size: 10px;
    color: grey;
    line-height: 20px;
}
.call-msg-time {
    font-size: 10px;
    color: grey;
}

.voicemail-message > p {
    padding: 10px;
    margin-right: 20px;
    overflow-wrap: break-word;
}
.voicemail-subject{
    display: flex;
}
.voicemail-subject-content{
    display: flex;
    flex-direction: column;
}

.voicemail-content {
    overflow: scroll;
    margin-top: 20px;
}

.voicemail-msg-text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.voicemail-msg{
    margin: 10px;
}

.voicemail-msg-title {
    font-weight: bold;
    color: #2585c0;
}

@media only screen and (max-width: 700px) {   
    .voicemail-bodyHeader{
    display: grid !important;
    margin-left: 0;
    }

    .voicemail-time {
        position: unset;
        margin-top: 5px;
    }
    
    .voicemail-bodyHeader > p{
        margin-left: 0;
    }
    .voicemail-message > p {
        margin-left: 10px;
    }
}