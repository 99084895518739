.subscription-price {
    font-size: 12px;
    margin: 5px;
}

.subscription-items {
    display: flex;
    justify-content: space-around;
}

.subscription-content {
}

.subscription-card {
    max-width: 350px;
    margin: 5px;
}

@media only screen and (max-width: 500px) {   

}