.list-assistant-icon {
    width: 25px;
    height: 25px;
}

.buttons-container {
    display: flex;
    justify-content: space-between;
}

.buttons-preview {
    flex: 0.3;
}
.buttons-list {
    flex: 0.75;
}